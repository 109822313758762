import React from 'react'
import { Typography, Link, Box, Icon, Stack, Paper } from '@mui/material'
import feature1 from "./images/feature_1.jpg"
import feature2 from "./images/feature_2.png"
import feature3 from "./images/feature_3.jpg"
import { BasicButton } from './button/Buttons.js'

export const FeaturesIntro = () => {
  const waitlistFormLink = "https://docs.google.com/forms/d/e/1FAIpQLScGK2zI6rplGXfCVfjkvfebMjtCySGpb40o0nlwxafesoTvdw/formrestricted";

  return (
    <Box>
    <Box sx = {{textAlign: 'center', m:{xs:6, sm:8, md:9}, justifyContent: "center", background: 'transparent', boxShadow: 'none'}}>
        <Typography variant='h4'  sx={{padding:1}}>
            What is superfeeds
        </Typography>
    </Box>
     <Stack 
      direction={{ xs: 'column', md: 'row'}} 
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{textAlign: 'center', 
      m:{xs:6, sm:8, md:9},
      background: 'transparent', boxShadow: 'none'
    }}
    >
       <Paper 
        sx={{  textAlign:  { xs: 'center', md: 'right'}, background: 'transparent', boxShadow: 'none',  
        display:{
          md:"none",
          lg: "none"
        } 
        }}
      >
        <Box
                    component="img"
                    sx={{ 
                        height: {
                            xs: 180,
                            sm: 250,
                            md: 350
                        },
                        display:"flex",
                        padding: "2",
                        background: 'transparent', boxShadow: 'none'
                       
                    }}
                    alt="Superfeeds"
                    src={feature1}
                />
        
      </Paper>

      {/* First column */}
      <Paper 
        sx={{ textAlign: { xs: 'center', md: 'left'}, padding:4, background: 'transparent', boxShadow: 'none'}}
      >
            <Typography  variant='h5'  sx={{ color: '#FFAE4B',
            display: "flex",
            maxWidth: 500,
            padding:2,
            background: 'transparent', boxShadow: 'none'
            }}>
                Search Conversations Across Crypto
            </Typography>
            <Typography  variant='p'  sx={{color: '#ffffff', 
            fontSize:{
                xs: 10,
                sm: 12,
                md: 18
            },
            display: "flex",
            maxWidth: 500,
            padding:2,
            background: 'transparent', boxShadow: 'none'
            }}>
               Dive deeper into discussions across various social media platforms, 
               spanning from Lens to Farcaster, and even Mirror. 
               Interested in a topic? See who is talking about it and why.
            </Typography>
      </Paper>

      {/* Second column */}
      <Paper 
        sx={{  textAlign:  { xs: 'center', md: 'right'}, background: 'transparent', boxShadow: 'none',
        display:{
          xs:"none",
          md: "flex"
      }  }}
      >
        <Box
                    component="img"
                    sx={{ 
                        height: {
                            xs: 180,
                            sm: 250,
                            md: 350
                        },
                        display:"flex",
                        padding: "2",
                        background: 'transparent', boxShadow: 'none'
                       
                    }}
                    alt="Superfeeds"
                    src={feature1}
                />
        
      </Paper>
      
    </Stack>
    <Stack 
      direction={{ xs: 'column', md: 'row'}} 
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{textAlign: 'center', m:{xs:6, sm:8, md:9},background: 'transparent', boxShadow: 'none'}}
    >
      {/* First column */}
      <Paper 
        sx={{  textAlign:  { xs: 'center', md: 'left'}, background: 'transparent', boxShadow: 'none',  
        display: "flex"
        }}
      >
        <Box
                    component="img"
                    sx={{ 
                        height: {
                            xs: 180,
                            sm: 250,
                            md: 350
                        },
                        display:"flex",
                        padding: "2",
                        background: 'transparent', boxShadow: 'none'
                       
                    }}
                    alt="Superfeeds"
                    src={feature2}
                />
        
      </Paper>

      

      {/* Second column */}

      <Paper 
        sx={{ textAlign: { xs: 'center', md: 'left'}, padding:4, background: 'transparent', boxShadow: 'none'}}
      >
            <Typography  variant='h5'  sx={{ color: '#FFAE4B',
            display: "flex",
            maxWidth: 500,
            padding:2,
            background: 'transparent', boxShadow: 'none'
            }}>
                 Insights For Creators and Users
            </Typography>
            <Typography  variant='p'  sx={{color: '#ffffff', 
            fontSize:{
                xs: 10,
                sm: 12,
                md: 18
            },
            display: "flex",
            maxWidth: 500,
            padding:2,
            background: 'transparent', boxShadow: 'none'
            }}>
               Gain invaluable insights into the latest social metrics, 
               look at insights for projects, users and any topics that pique your interest. 
               See how conversations have changed over time for your favouritie project and 
               whether you should HODL or sell based on social chatter.
            </Typography>
      </Paper>

      {/* <Paper 
        sx={{  textAlign:  { xs: 'center', md: 'left'}, background: 'transparent', boxShadow: 'none',  
        display:{
            md:"none",
            lg: "none"
        } 
        }}
      >
        <Box
                    component="img"
                    sx={{ 
                        height: {
                            xs: 180,
                            sm: 250,
                            md: 350
                        },
                        display:"flex",
                        padding: "2",
                        background: 'transparent', boxShadow: 'none'
                       
                    }}
                    alt="Superfeeds"
                    src={feature2}
                />
        
      </Paper>
      */}
      
    </Stack>
    <Stack 
      direction={{ xs: 'column', md: 'row'}} 
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{textAlign: 'center', m:{xs:6, sm:8, md:9},background: 'transparent', boxShadow: 'none'}}
    >
       <Paper 
        sx={{  textAlign:  { xs: 'center', md: 'right'}, background:"transparent", boxShadow: 'none',
        display:{
          md:"none",
          lg: "none"
        }  
      }}
      >
        <Box
                    component="img"
                    sx={{ 
                        height: {
                            xs: 180,
                            sm: 250,
                            md: 350
                        },
                        display:"flex",
                        padding: "2",
                        background: 'transparent', boxShadow: 'none'
                       
                    }}
                    alt="Superfeeds"
                    src={feature3}
                />
        
      </Paper>

      {/* First column */}
      <Paper 
        sx={{ textAlign: { xs: 'center', md: 'left'}, padding:4, background: 'transparent', boxShadow: 'none'}}
      >
            <Typography  variant='h5'  sx={{ color: '#FFAE4B',
            display: "flex",
            maxWidth: 500,
            padding:2,
            background: 'transparent', boxShadow: 'none'
            }}>
                Precision with Advanced Search Filters
            </Typography>
            <Typography  variant='p'  sx={{color: '#ffffff', 
            fontSize:{
                xs: 10,
                sm: 12,
                md: 18
            },
            display: "flex",
            maxWidth: 500,
            padding:2,
            background: 'transparent', boxShadow: 'none'
            }}>
               Our user-friendly filters empower you to cut through the noise effortlessly. 
               Tailor your search by refining results based on date, engagement metrics, keywords, 
               language, and the exclusion of undesired content like links or replies.
            </Typography>
      </Paper>

      {/* Second column */}
      <Paper 
        sx={{  textAlign:  { xs: 'center', md: 'right'}, background: 'transparent', boxShadow: 'none',
        display:{
          xs:"none",
          md: "flex"
      } }}
      >
        <Box
                    component="img"
                    sx={{ 
                        height: {
                            xs: 180,
                            sm: 250,
                            md: 350
                        },
                        display:"flex",
                        padding: "2",
                        background: 'transparent', boxShadow: 'none'
                       
                    }}
                    alt="Superfeeds"
                    src={feature3}
                />
        
      </Paper>
      
    </Stack>
    <Box sx = {{textAlign: 'center', m:{xs:6, sm:8, md:9}, justifyContent: "center"}}>
        <Typography variant='h4'  sx={{padding:1, color: '#FFAE4B'}}>
            Unlock Exclusive Benefits
        </Typography>
        <Typography variant='h5'  sx={{padding:1, color: '#ffffff'}}>
            - Join the Waitlist!
        </Typography>
        <Link href = {waitlistFormLink} target="_blank" >
        <BasicButton variant='contained'>
          Be the First to Know
        </BasicButton>
      </Link>
    </Box>
    </Box>
  )
}
