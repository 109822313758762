import React, { useState, useEffect } from 'react';
import './word-animation.css';

const WordAnimation = ({ words = ["Hello", "World", "React", "Animation"], letterInterval = 100, wordInterval = 2000 }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
  
  useEffect(() => {
    // Handle letter animation
    if (currentLetterIndex < words[currentWordIndex].length) {
      const letterTimer = setTimeout(() => {
        setCurrentLetterIndex(currentLetterIndex + 1);
      }, letterInterval);
      return () => clearTimeout(letterTimer);
    } else {
      // Handle transition to next word after the current word completes
      const wordTimer = setTimeout(() => {
        setCurrentWordIndex((currentWordIndex + 1) % words.length);
        setCurrentLetterIndex(0);
      }, wordInterval);
      return () => clearTimeout(wordTimer);
    }
  }, [words, currentWordIndex, currentLetterIndex, letterInterval, wordInterval]);

  return <span>{words[currentWordIndex].slice(0, currentLetterIndex)}</span>;
}

export default WordAnimation;
