import React from 'react';
import { Typography, Paper, Box, Divider, List, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';

function TermsAndConditions() {
  return (
      <Box sx = {{textAlign: 'left', m:{xs:2, sm:3, md:5}, justifyContent: "center", background: 'transparent', boxShadow: 'none'}}>
        <Paper style={{ padding: {xs:2, sm:3, md:5}, background: 'transparent', boxShadow: 'none' }}>
          <Typography variant="h4" gutterBottom sx = {{maxWidth: 1300}}>
            Terms and Conditions
          </Typography>

          <Divider style={{ marginBottom: 20 }} />

          <Typography variant="p" gutterBottom sx = {{maxWidth: 1300}}>
          Last updated: April 09, 2023
          <br />
          Please read these terms and conditions carefully before using Our Service.
          </Typography>

          <Divider style={{ marginBottom: 20 }} />
          
          <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph sx = {{maxWidth: 1300}}>
            By accessing or using the SuperFeeds.ai website and services (referred to as "we," "us," or "our"), you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use our services
          </Typography>

          <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            2. Changes to Terms
          </Typography>
          <Typography paragraph sx = {{maxWidth: 1300}}>
          We reserve the right to update or modify these Terms and Conditions at any time without prior notice. The most recent version will be posted on our website, and the date of the latest revision will be indicated at the beginning of the Terms and Conditions. Your continued use of our services after any changes constitutes your acceptance of the updated Terms and Conditions.
          </Typography>

         

          <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            3.  Use of Services
          </Typography>
          <Typography paragraph sx = {{maxWidth: 1300}}>
          You must be at least 18 years old to use our services. By using our services, you represent that you meet this age requirement.
            </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            4.  User Accounts
        </Typography>
        <Typography variant="h7" gutterBottom sx = {{paddingLeft: 2, maxWidth: 1300}}>
            4.1  Registration
        </Typography>
        <Typography paragraph sx = {{paddingLeft: 2, paddingTop:1, maxWidth: 1300}}>
            Some features of our services may require you to register and create a user account. You agree to provide accurate, current, and complete information during the registration process and to update this information to keep it accurate, current, and complete.
        </Typography>
        <Typography variant="h7" gutterBottom sx = {{paddingLeft: 2, maxWidth: 1300}}>
            4.2  Account Security
        </Typography>
        <Typography paragraph sx = {{paddingLeft: 2, paddingTop:1, maxWidth: 1300}}>
        You are responsible for maintaining the security of your user account and password. You agree not to disclose your password to any third party and to notify us immediately of any unauthorized use of your account.
        </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            5. User Content
        </Typography>
        <Typography variant="h7" gutterBottom sx = {{paddingLeft: 2, maxWidth: 1300}}>
            5.1  User Responsibilities
        </Typography>
        <Typography paragraph sx = {{paddingLeft: 2, paddingTop:1, maxWidth: 1300}}>
        You are solely responsible for the content you post, upload, or share on our platform. You agree not to post, upload, or share content that is illegal, defamatory, obscene, abusive, or violates the rights of others.
        </Typography>
        <Typography variant="h7" gutterBottom sx = {{paddingLeft: 2, maxWidth: 1300}}>
            5.2  Rights to User Content
        </Typography>
        <Typography paragraph sx = {{paddingLeft: 2, paddingTop:1, maxWidth: 1300}}>
        By posting, uploading, or sharing content on our platform, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, publish, and distribute the content for the purpose of providing our services.
        </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            6.  Intellectual Property
        </Typography>
        <Typography variant="h7" gutterBottom sx = {{paddingLeft: 2, maxWidth: 1300}}>
            6.1  Our Content
        </Typography>
        <Typography paragraph sx = {{paddingLeft: 2, paddingTop:1, maxWidth: 1300}}>
        All content, including text, graphics, logos, and software, provided by SuperFeeds.ai is the property of SuperFeeds.ai and protected by copyright and other intellectual property laws.
        </Typography>
        <Typography variant="h7" gutterBottom sx = {{paddingLeft: 2, maxWidth: 1300}}>
            6.2   User Content
        </Typography>
        <Typography paragraph sx = {{paddingLeft: 2, paddingTop:1, maxWidth: 1300}}>
        We respect the intellectual property rights of others and expect our users to do the same. If you believe that your intellectual property rights have been infringed upon, please contact us with a detailed description of the alleged infringement.
        </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            7.  Termination
        </Typography>
        <Typography paragraph sx = {{maxWidth: 1300}}>
        We reserve the right to suspend or terminate your access to our services at our discretion, with or without cause and without notice.
        </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            8.  Disclaimer of Warranties
        </Typography>
        <Typography paragraph sx = {{maxWidth: 1300}}>
        Our services are provided "as is" and "as available" without any warranties, express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
        </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            9.   Limitation of Liability
        </Typography>
        <Typography paragraph sx = {{maxWidth: 1300}}>
        We shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
        <br />
        <List
            sx={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside'
                }}
        >
        <ListItem sx={{ display: 'list-item' }}>
            Your use or inability to use our services.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
            Any unauthorized access to or use of our servers and/or any personal information stored therein.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
            Any interruption or cessation of our services.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
            Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services.
        </ListItem>
        </List>
        </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            10.  Governing Law and Jurisdiction
        </Typography>
        <Typography paragraph sx = {{maxWidth: 1300}}>
            These Terms and Conditions shall be governed by and construed in accordance with the laws of Singapore, without regard to its conflict of law principles. Any dispute arising from or relating to these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts located in Singapore.
        </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            11.   Contact Us
        </Typography>
        <Typography paragraph sx = {{maxWidth: 1300}}>
        If you have any questions or concerns about these Terms and Conditions, please contact us at:
        <br />
        <Link href="mailto:contact@superfeeds.ai">
            contact@superfeeds.ai
        </Link>
        </Typography>

        </Paper>
      </Box>
  );
}

export default TermsAndConditions;
