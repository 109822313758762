import React, { useState, useRef} from 'react'
import { Typography, Link, Box, Icon } from '@mui/material'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'
import WordAnimation from './word-animation/word-animation.js'
import { BasicButton } from './button/Buttons.js';


export const Introduction = () => {

  const animationWords = ["Projects", "People"];
  const waitlistFormLink = "https://docs.google.com/forms/d/e/1FAIpQLScGK2zI6rplGXfCVfjkvfebMjtCySGpb40o0nlwxafesoTvdw/formrestricted";
  
  return (
    <Box sx = {{textAlign: 'center', m:{xs:7, sm:9, md:15}, justifyContent: "center"}}>
      <Typography variant='h4'  sx={{padding:1}}>
        Search Conversations Around <span style={{ color: '#FFAE4B', textDecoration: 'underline' }}><WordAnimation words = {animationWords}/></span>
        <br />Happening In Crypto and Web3
      </Typography>
      <Typography variant='p' color='#FFAE4B' sx={{padding:1}}>
        Join superfeeds waiting list and get early access.
      </Typography>
      <br />
      <br />
      <Link href = {waitlistFormLink} target="_blank" >
        <BasicButton variant='contained'>
          Join Waitlist
        </BasicButton>
      </Link>
      <br />
      <Box sx={{display:'inline-flex'}}>
        <Icon component={GppGoodOutlinedIcon} sx={{height:{xs:7, sm:12}, color:'#778899'}}></Icon>
        <Typography variant='p' color='#778899' sx = {{height:{xs:8, sm:13}, fontSize:{xs:5, sm:10}}}>
          Your data is safe with us.We don't spam.
        </Typography>
      </Box>
    </Box>
  )
}