import React,  { useRef }  from "react";
import { Introduction}  from "./Introduction";
import { Typography, Link, Box, Icon, Button } from '@mui/material'
import { FeaturesIntro } from "./FeaturesIntro";
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
const HomeContent = () => {

  // Create a ref for the target component
  const targetComponentRef = useRef(null);

  // Handler to scroll to the target component
  const scrollToTarget = () => {
    targetComponentRef.current.scrollIntoView({
      behavior: 'auto',
      block: 'start' // Smooth scroll
    });
  };

  return (
    <Box>
      <Introduction />
      <Box sx = {{textAlign: 'center', justifyContent: "center"}}>
        <Typography variant='p' color='#ffffff'>
          Explore our features
        </Typography>
        <br />
        <Button onClick={scrollToTarget} sx ={{
           color: ' #581C90', 
           ":hover": {
             color: "#9A1C70"
           }
        }}>
        <Icon component={KeyboardDoubleArrowDownOutlinedIcon} fontSize="large"></Icon>
        </Button>
      </Box>
      <br />
      <br />
      <br />
      <br />
      <Box  ref={targetComponentRef}>
      <FeaturesIntro />
      </Box>
    </Box>
  );
};

export default HomeContent;
