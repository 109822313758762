import React from 'react'
import { Typography, Paper, Box, Divider, List, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';

export const PrivacyPolicy = () => {
  return (
    <Box sx = {{textAlign: 'left', m:{xs:2, sm:3, md:5}, justifyContent: "center", background: 'transparent', boxShadow: 'none'}}>
        <Paper style={{ padding: {xs:2, sm:3, md:5}, background: 'transparent', boxShadow: 'none' }}>
          <Typography variant="h4" gutterBottom sx = {{maxWidth: 1300}}>
            Privacy Policy
          </Typography>

          <Divider style={{ marginBottom: 20 }} />

          <Typography variant="p" gutterBottom sx = {{maxWidth: 1300}}>
          Last Updated: 19th September 2023
          <br />
          Please read our privacy policy carefully before using Our Service.
          </Typography>

          <Divider style={{ marginBottom: 20 }} />
          
          <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
          Introduction
          </Typography>
          <Typography paragraph sx = {{maxWidth: 1300}}>
            SuperFeeds.ai ("we," "us," or "our") is committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your information when you visit our website or use our services.
          </Typography>
          <Typography paragraph sx = {{maxWidth: 1300}}>
          By accessing or using SuperFeeds.ai, you consent to the practices described in this Privacy Policy. Please read this Privacy Policy carefully, and if you do not agree with our practices, do not use our services.
          </Typography>

          <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
          Information We Collect
          </Typography>
          <Typography paragraph sx = {{maxWidth: 1300}}>
          Information You Provide: When you use our services, you may provide us with personal information, including but not limited to your name, email address, and other contact information.
          </Typography>
          <Typography paragraph sx = {{maxWidth: 1300}}>
          Automatically Collected Information: We may collect certain information automatically when you visit our website or use our services. This may include your IP address, browser type, device type, operating system, and usage data.
          </Typography>

         

          <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
          How We Use Your Information
          </Typography>
          <Typography paragraph sx = {{maxWidth: 1300}}>
          We may use the information we collect for the following purposes:
          <br />
        <List
            sx={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside'
                }}
        >
        <ListItem sx={{ display: 'list-item' }}>
        Provide and Improve Services: To provide, maintain, and improve our services, respond to your inquiries, and enhance user experience.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
        Communications: To send you updates, newsletters, and other promotional materials. You can opt-out of these communications at any time.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
        Analytics: To analyze usage patterns and trends, diagnose technical issues, and track user interactions with our website and services.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
        Legal Compliance: To comply with legal obligations, respond to legal requests, and protect our rights, privacy, safety, and property, or that of others.
        </ListItem>
        </List>
          </Typography>

        

          <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
          Information Sharing
          </Typography>
          <Typography paragraph sx = {{maxWidth: 1300}}>
          We do not sell, trade, or rent your personal information to third parties. However, we may share your information with:
          <br />
        <List
            sx={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside'
                }}
        >
        <ListItem sx={{ display: 'list-item' }}>
        Service Providers: We may share your information with third-party service providers who assist us in providing and improving our services. These providers are contractually obligated to protect your information.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
        Legal Requirements: We may disclose your information if required by law or in response to a valid legal request, such as a subpoena or court order.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
        Business Transfers: In the event of a merger, acquisition, or sale of all or part of our assets, your information may be transferred as part of the transaction.
        </ListItem>
        </List>
          </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
        Security
        </Typography>
        <Typography paragraph sx = {{maxWidth: 1300}}>
        We implement reasonable security measures to protect your information. However, no method of transmission or storage is completely secure. We cannot guarantee the security of your data.
        </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
        Your Choices
        </Typography>
        <Typography paragraph sx = {{maxWidth: 1300}}>
        You can exercise certain rights regarding your personal information. You have the right to:
        <List
            sx={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside'
                }}
        >
        <ListItem sx={{ display: 'list-item' }}>
        Access and Correct Your Information: You can access and update your personal information by contacting us.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
        Opt-Out: You can opt-out of receiving promotional emails from us by following the instructions in the emails or by contacting us directly.
        </ListItem>
        </List>
        </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
        Changes to this Privacy Policy
        </Typography>
        <Typography paragraph sx = {{paddingLeft: 2, paddingTop:1, maxWidth: 1300}}>
        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The most recent version will be posted on our website, and the date of the latest revision will be indicated at the beginning of the Privacy Policy.
        </Typography>

        <Typography variant="h6" gutterBottom sx = {{maxWidth: 1300}}>
            Contact Us
        </Typography>
        <Typography paragraph sx = {{maxWidth: 1300}}>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
        <br />
        <Link href="mailto:contact@superfeeds.ai">
            contact@superfeeds.ai
        </Link>
        </Typography>

        </Paper>
      </Box>
  )
}
