import React from 'react';
import styled from '@emotion/styled'
import Button from '@mui/material/Button';

export const BasicButton = styled(Button)({
    color: 'white', 
    backgroundColor: ' #581C90', 
    borderColor: ' #581C90',
    ":hover": {
      bgcolor: "#9A1C70",
      color: "white"
    },
    width: {
        xs: 150,
        sm: 200,
    },
    padding: {
        xs: 1,
        sm: 5,
    }
})

