import HomeContent from "./components/HomeContent";
import { Footer } from "./components/footer/Footer";
import { Navbar } from "./components/navbar/Navbar"
import { Pages } from "./Pages"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter
} from "react-router-dom";
import { Box, Stack } from "@mui/material";

function App() {
  return (
    <Box >
      <Navbar />
      <Pages />
      <Footer />
    </Box>
  );
}

export default App;
