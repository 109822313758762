import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography, Link, Stack } from "@mui/material";
import largeLogoDark from '../images/logo/logo-dark-large.png'
import smallLogoDark from '../images/logo/logo-dark-small-2.png'



export const Footer = () => {
  return (
    <Box sx = {{textAlign: 'center', m:{xs:6, sm:8, md:9}, justifyContent: "center"}}>
       <Link href="/">
                <Box
                    component="img"
                    sx={{ 
                        height: 50,
                        display: {
                          xs: "none",
                          sm: "inline-block",
                      },
                        padding: "2",
                      
                       
                    }}
                    alt="Superfeeds"
                    src={largeLogoDark}
                />
                <Box
                    component="img"
                    sx={{ 
                        height: 55,
                        display: {
                            xs: "inline-block",
                            sm: "none",
                        },
                        padding: "2",
                        justifyContent: "center"
                    }}
                    alt="Superfeeds"
                    src={smallLogoDark}
                />
        </Link>
        <br />
        <br />
        <Stack direction="row" justifyContent="space-between" >
        <Link href="/" color='#555a5f'>
          <Typography variant="p" color='#555a5f' sx = {{fontSize:{xs:10, sm:15}}}>
            &copy; Superfeeds {new Date().getFullYear()}
          </Typography>
        </Link>
          
          <Link href="/privacy-policy" color='#555a5f'>
            <Typography variant="p" color='#555a5f' sx = {{fontSize:{xs:10, sm:15}}}>
              Privacy Policy
            </Typography>
          </Link>
          
          <Link href="/terms-and-conditions" color='#555a5f'>
            <Typography variant="p" color='#555a5f' sx = {{fontSize:{xs:10, sm:15}}}>
              Terms of Conditions
            </Typography>
          </Link>
        </Stack>
    </Box>
  )
}
