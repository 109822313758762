import styled from '@emotion/styled'
import { AppBar, Toolbar, Link, Box } from '@mui/material'
import largeLogoDark from '../images/logo/logo-dark-large.png'
import smallLogoDark from '../images/logo/logo-dark-small-2.png'
import React from 'react'
import { BasicButton } from '../button/Buttons'

const StyledToolBar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between"
})

const waitlistFormLink = "https://docs.google.com/forms/d/e/1FAIpQLScGK2zI6rplGXfCVfjkvfebMjtCySGpb40o0nlwxafesoTvdw/formrestricted";

export const Navbar = () => {
  return (
    <AppBar position='static' sx = {{ background: 'transparent', boxShadow: 'none'}}>
        <StyledToolBar>
            <Link href="/">
                <Box
                    component="img"
                    sx={{ 
                        height: 50,
                        display: {
                            xs: "none",
                            sm: "flex",
                        },
                        padding: "2"
                       
                    }}
                    alt="Superfeeds"
                    src={largeLogoDark}
                />
                <Box
                    component="img"
                    sx={{ 
                        height: 55,
                        display: {
                            xs: "flex",
                            sm: "none",
                        },
                        padding: "2"
                    }}
                    alt="Superfeeds"
                    src={smallLogoDark}
                />
            </Link>
            <Link href = {waitlistFormLink} target="_blank">
                <BasicButton variant='contained'>
                    Join Waitlist
                </BasicButton>
            </Link>
        </StyledToolBar>
    </AppBar>
  )
}
