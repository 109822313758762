import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import TermsAndConditions from './components/TermsAndConditions';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import HomeContent from "./components/HomeContent";

export const Pages = () => {
  return (
    <Router>

    <Routes>
      <Route path="/" element={ <HomeContent /> }></Route>
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>

   
    </Router>
  )
}

